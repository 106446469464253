<template>
    <div>
        <Navbar :page="`Diagnósticos ${diagnostico && diagnostico.tipo_responsavel === 'fornecedor' && diagnostico.fornecedor && diagnostico.fornecedor.nome ? diagnostico.fornecedor.nome :  diagnostico && diagnostico.tema ? diagnostico.tema : ''}`" link="/diagnosticos" nameLink="Diagnósticos" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <div class="bg-gray-200 py-2 px-4 rounded">
                <div class="grid grid-cols-12 gap-2">
                    <div class="col-span-5">
                        <label class="text-left text-xs font-medium text-gray-500 tracking-wider">Questão</label>
                    </div>
                </div>
            </div>
            <div v-for="(item) in perguntas" :key="item._id" class="border-b-2 border-gray-200 py-2 px-4 mb-2">
                <div class="grid grid-cols-12 gap-2">
                    <div class="col-span-12 md:col-span-5">
                        <label class="text-left text-sm font-medium text-black"><b>{{ item.ref}} - </b>{{ item.pergunta }}</label>
                    </div>
                    <div class="col-span-12 md:col-span-12">
                        <table v-if="item.evidencias && item.evidencias.length" class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                                <th scope="col" class="px-2 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">Evidências</th>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="(doc) in item.evidencias" :key="doc.filename">
                                <td class="px-2 py-2 whitespace-nowrap">
                                    <a 
                                    type="button"
                                    target="_blank"
                                    :href="`${url_api}/upload?mimetype=${doc.mimetype}&filename=${doc.filename}&folder=empresas`"
                                    class="text-left text-sm font-medium text-blue-500 underline">
                                    {{doc.originalname}}
                                    </a>
                                </td>
                                </tr>
                            </tbody>
                        </table>
                        <label v-else class="text-left text-sm font-medium text-black"><b>Nenhuma Evidência anexada </b></label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            route: 'diagnosticos',
            diagnostico: null,
            perguntas: [],
        }
    },
    methods: {
        async start(){
            const id = this.$route.params.id;

            if (id) {
                const req = await this.$http.get(`/get-diagnosticos/${id}`);
                this.diagnostico = req.data.data;
                this.perguntas = req.data.perguntas;
            }
        },
    },
    async beforeMount() {
        this.start();
    },
}
</script>